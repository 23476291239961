const aboutcard2info = [
  {
    id: 1,
    image:
      'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-7.jpg',
    title: 'What_we_do',
    desc: 'What_we_do_desc',
  },
  {
    id: 2,
    image:
      'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-4.jpg',
    title: 'Who_we_are',
    desc: 'Who_we_are_desc',
  },
  {
    id: 3,
    image:
      'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-8.jpg',
    title: 'How_it_work',
    desc: 'How_it_work_desc',
  },
]

export default aboutcard2info
